/* Tables */

.table {
  margin-bottom: 0;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      color: $text-muted;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    line-height: 1;
    white-space: nowrap;
  }
  td {
    font-size: $default-font-size;
    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
  &.table-bordered {
    border-top: 1px solid $border-color;
  }
  &.table-dark {
    thead {
      tr {
        th {
          color: lighten($text-muted, 30%);
        }
      }
    }
  }
}

.table{
    thead{
        tr{
            th{
                &.today-date{
                    background-color: #f89b24;
                    color: white;
                }
            }
        }
    }
}

.table{
    tbody{
        tr{
            td{
                &.table-stock{
                    //background: theme-color(success);
                    color: theme-color(success);
                }
                &.table-reorder{
                    //background: theme-color(warning);
                    color: theme-color(warning);
                }
                &.table-no-stock{
                    //background: theme-color(danger);
                    color: theme-color(danger);
                }
            }
        }
    }
}

.table{
    tbody{
        tr{
            td{
                &.table-green{
                    //background: theme-color(success);
                    color: theme-color(success);
                }
                &.table-yellow{
                    //background: theme-color(warning);
                    color: theme-color(warning);
                }
                &.table-red{
                    //background: theme-color(danger);
                    color: theme-color(danger);
                }
                &.table-blue{
                    //background: theme-color(danger);
                    color: theme-color(secondary);
                }
            }
        }
    }
}
